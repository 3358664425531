@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400;500&display=swap');

body {
  background-color: whitesmoke !important;
  font-family: 'Barlow', sans-serif !important;
  font-size: 20px;
  /* font-weight: 300; */
  /* background-image: url('assets/sfondo1.png');
  background-attachment: fixed;
  background-size: cover; */
}

html {
  --blue-cinetel: rgb(0, 109, 151);
  --arancio-cinetel: rgb(255, 102, 0);
}

.active:not(.tab-pane) {
  background-color: var(--blue-cinetel) !important;
  color: white !important;
  /* border-color:blue!important; */
  z-index: 3;
}

.tasto-periodo:hover {
  /* border-color: gray; */
  background-color: whitesmoke;
  /* z-index: 3; */
}

.tasto-periodo {
  border: 1px solid gainsboro;
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  background-color: white;
  transition: 0.5s;
  margin-right: -1px;
  /* margin:1px; */
  /* border-radius: 5px; */

  line-height: 1.2;
  text-transform: uppercase;
}

.nav-attivo {
  color: white !important
}

.hover-header { 
  cursor: pointer;
  transition: 0.3s;
}
.hover-header:not(.active-header):hover {
  background-color: cadetblue!important;
}
.active-header {
  background-color: var(--arancio-cinetel);
  cursor: default;
}

nav {
  background-color: var(--blue-cinetel) !important;
}

nav .nav-link {
  font-size: 1.1rem;
}

.item-classifica {
  background-color: white;
  transition: 0.5s;
}

.item-classifica:hover {
  background-color: var(--blue-cinetel);
  color: white
}
.item-classifica:hover span {
  color: white!important
}

.item-classifica:first-of-type:hover {
  background-color: var(--arancio-cinetel);
}

.item-classifica>div:first-of-type {
  background-color: var(--blue-cinetel);
}

.item-classifica:first-of-type>div:first-of-type {
  background-color: var(--arancio-cinetel) !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data_centrale {
  background-color: rgb(255, 102, 0);
  color: black;
}

.data_selezionata {
  background-color: rgb(0, 109, 151)!important;
  color: white!important;
}

.day-calendar:not(.data_centrale):not(.data_selezionata):nth-child(odd) {
  background-color: whitesmoke;
}

.pannello {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
}

#accordion-regioni .accordion-body .row:not(:last-child) {
  border-bottom: 1px solid gainsboro;
}


.pvtUi {
  margin: 0 auto
}

button:focus, label:focus {
  box-shadow: none !important;
}

.btn-group .btn-outline-primary {
  border-color: var(--blue-cinetel) !important;
  color: var(--blue-cinetel) !important;
}
.btn-group .btn-outline-primary:hover {
  background-color: var(--blue-cinetel) !important;
  color: white!important
}

.btn-check:checked+.btn-outline-primary {
  border-color: var(--blue-cinetel) !important;
  background-color: var(--blue-cinetel) !important;
  color: white!important
}



.btn-outline-blue-cinetel {
  color: var(--blue-cinetel) !important;
  border-color: var(--blue-cinetel) !important;
}

.btn-outline-blue-cinetel:hover,
.btn-check:checked+.btn-outline-blue-cinetel {
  color: white !important;
  background-color: var(--blue-cinetel) !important;
}

.btn-blue-cinetel {
  color: white !important;
  border-color: var(--blue-cinetel) !important;
  background-color: var(--blue-cinetel) !important;
}

.accordion-button::after {
  display: none; /* Rimuove la freccia */
}

.accordion-button {
  padding-right: 0; /* Rimuove lo spazio riservato alla freccia */
}


.accordion-item {
  border: none; /* Rimuove il bordo dell'intero accordion item */
}

.accordion-button {
  border: none; /* Rimuove il bordo del bottone dell'accordion */
  background-color: transparent; /* Rende lo sfondo trasparente */
}

.accordion-body {
  border-top: none; /* Rimuove il bordo superiore tra il bottone e il corpo dell'accordion */
}


.btn-blue-cinetel:hover,
.btn-check:checked+.btn-blue-cinetel {
  border-color: var(--arancio-cinetel) !important;
  background-color: var(--arancio-cinetel) !important;
}


.modal-trasparente .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.item-filtro {
  cursor: pointer
}

.item-filtro span,
.item-filtro svg {
  transition: 0.2s;
}

.item-filtro:hover svg {
  color: var(--arancio-cinetel);
}

.item-filtro:hover span {
  color: var(--arancio-cinetel)
}

.badge-blue-cinetel {
  background-color: var(--blue-cinetel)!important;
}

.badge-arancio-cinetel {
  background-color: var(--arancio-cinetel)!important;
}

.link-bianco {
  color: white;
  transition: 0.4s;
  text-decoration: none;
}
.link-bianco:hover {
  color: whitesmoke
}

.link-blue  {
  color: var(--blue-cinetel);
  transition: 0.4s;
  text-decoration: none;
}
.link-blue:hover {
  color: blue
}


.scollabile::-webkit-scrollbar {
  width: 0.4em;
}

.scollabile::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.scollabile::-webkit-scrollbar-thumb {
background-color: rgb(0, 109, 151);
/* outline: 1px solid slategrey; */
}

.realtime-link>a {
  color: rgb(255, 102, 0) !important;
}

.cittaSmall {
  display: block;
  font-size: smaller !important;
}

.p-disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}

.p-multiselect-label {
  white-space: normal !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.p-multiselect-panel {
  min-height: 29rem;
}

.pvtRowOrder {
  position: relative;
}
.pvtRowOrder:hover:before, .pvtRowOrder:hover:after {
  display:block;
}
.pvtRowOrder:before {
  content: "Ordina righe"; /* here's the magic */
  position:absolute;
  
  /* vertically center */
  top:-50%;
  transform:translateX(-90%);
  z-index: 90;
  /* move to right */
  top:133%;
  margin-left:15px; /* and add a small left margin */
  
  /* basic styles */
  width:fit-content;
  padding:5px;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  opacity: 0.9;
  background:#121212;
  color: #f2f2f2;
  text-align:center;

  display:none; /* hide by default */
}
.pvtRowOrder:after {
  content: "";
  position:absolute;
  
  /* position tooltip correctly */
  /* left:100%; */
  /* margin-right:-5px; */
 
  /* vertically center */
  /* top:50%; */
  transform:translateY(-50%);
 
  /* the arrow */
  border:10px solid #121212;
  border-color: transparent transparent #121212 transparent;
  
  display:none;
}

.pvtColOrder {
  position: relative;
}

.pvtColOrder:hover:before, .pvtColOrder:hover:after {
  display:block;
}

.pvtColOrder:before {
  content: "Ordina Colonne"; /* here's the magic */
  position:absolute;
  
  /* vertically center */
  top:-50%;
  transform:translateX(-90%);
  z-index: 90;
  /* move to right */
  top:133%;
  margin-left:15px; /* and add a small left margin */
  
  /* basic styles */
  width:fit-content;
  padding:5px;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  opacity: 0.9;
  background:#121212;
  color: #f2f2f2;
  text-align:center;

  display:none; /* hide by default */
}

.pvtColOrder:after {
  content: "";
  position:absolute;
  
  /* position tooltip correctly */
  /* left:100%; */
  /* margin-right:-5px; */
 
  /* vertically center */
  /* top:50%; */
  transform:translateY(-50%);
 
  /* the arrow */
  border:10px solid #121212;
  border-color: transparent transparent #121212 transparent;
  
  display:none;
}